@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=PT+Mono&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.App {
  text-align: center;
}

.btn {
  width: max-content;
  display: inline-block;
  color: white;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid white;

  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0px;
  font-size: 1rem;
}

.btn:hover {
  background: white;
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}

.btn-primary {
  background: white;
  color: rgba(0, 0, 0, 0.5);
}

.btn-primary:hover {
  background: transparent;
  color: white;
  border-color: white;
}

.text_light {
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.backgrounding {
  height: 100vh;
  background: linear-gradient(to bottom, rgba(21, 21, 30,0.1) 35%,
  rgba(255, 255, 255, 255)), url(assets/background.jpg);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
  background-size: cover;
}

.backgrounding h1{
  margin-top: 0px;
  color: white;
  font-family: "Playfair Display", system-ui;
  font-weight: 700;
  font-style: italic;
  font-size: 80px;
  margin-bottom: 2%;
}

.about h1 {
  margin-top: 0px;
  color: black;
  font-family: "Playfair Display", system-ui;
  font-weight: 700;
  font-style: italic;
  font-size: 40px;
  margin-bottom: 10%;
}

.projects h1 {
  margin-top: 0px;
  color: black;
  font-family: "Playfair Display", system-ui;
  font-weight: 700;
  font-style: italic;
  font-size: 40px;
  margin-bottom: 10%;
}

.drawer p {
  margin-top: 3%;
  color: black;
  font-family: "Playfair Display", system-ui;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}

.subtitles h1{
  font-weight: 300;
  font-style: normal;
  margin-bottom: 3%;
  font-size: 23px;
}

.subtitles h1:after{
  font-weight: 700;
  content: " Work Experience";
  font-style: italic;
  margin-bottom: 3%;
  font-size: 25px;
}


.drawer h3 {
  margin-top: 0px;
  color: black;
  font-family: "Playfair Display", system-ui;
  font-weight: 700;
  font-style: italic;
  font-size: 25px;
  margin-bottom: 0%;
}

.backgrounding h2 {
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: white;
  margin-bottom: 0px;
  font-size: 30px;
}

.about h2{
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: black;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 25px;
}

.projects h2{
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: black;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 25px;
}

.about h5{
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: black;
  margin-top: 0%;
  margin-bottom: 5%;
  font-size: 15px;
}

.backgrounding h3 {
  margin-top: 0px;
}

.typetext {
  margin-top: 0px;
  color: white;
  font-family: "Playfair Display", system-ui;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
}

.about {
  min-height: 100vh;
  width: 100%;
}

.projects {
  margin-top: 10%;
  min-height: 100vh;
  width: 100%;
}

.projects-content {
  height:100%;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.projects-list {
  height:100%;
  margin-left: auto;
  margin-right: auto;
}
.about_content {
  height: 100%;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  padding-top: 10%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.project {
  padding: 5px 11px 11px 11px;
  background-color: #5c5c76;
  border-radius: 0.4rem;
  margin-bottom: 5%;
  border: solid 1px white;
}

.project-text {
  padding: 10px;
}

.project-text h1 {
  margin-top: 0px;
  color: white;
  font-family: "Playfair Display", system-ui;
  font-weight: 500;
  font-style: italic;
  font-size: 20px;
  margin-bottom: 1%;
}

.project-text h2{
  margin-top: 0px;
  color: white;
  font-family: "Playfair Display", system-ui;
  font-weight: 100;
  font-style: normal;
  font-size: 14px;
}

.project-row {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 25% 75%;
}

.project-column {
  height: 100%;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
}

.column {
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.column img{
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: 90%;
  object-fit: cover;
  display:block;
  border-radius: 0.4rem;
  box-shadow: 10px -10px #5c5c76;
}

.drawer {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
  width: 95%;
  padding: 13px;
  border-radius: 0.4rem;
}


.Collapsible__trigger:hover {
  background-color: white;
  border-color: #6b6888;
  color: black;
}

.Collapsible__trigger {
    display: block;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #6b6888;
    color: white;
    border-radius: 0.4rem;
    font-family: "Playfair Display", system-ui;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    margin-bottom: 2%;

  &:before{
    font-family: "Playfair Display", system-ui;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }


    &:after {
      font-family: 'FontAwesome';
      content: "\f107";
      position: absolute;
      right: 15px;
      top: 13px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

.contact {
  display: flex;
  gap:1.2rem;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a:link {
  text-decoration: none;
}

.navbar {
  background: rgba(0,0,0,0.2);
  width:max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position:fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius:3rem;
  backdrop-filter: blur(15px);

}

.navbar a{
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: #6b6888;
  font-size:1.1rem;
}

.navbar a:hover{
  background: rgba(0,0,0,0.3);
}

.navbar a.active{
  background: white;
  color: #6b6888;
}

.footer-row {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  height: 100%;

  margin-bottom: 7%;
}

.footer h1 {
  margin-top: 1.5%;
  color: #6b6888;
  font-family: "Playfair Display", system-ui;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  margin-bottom: 0%;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  left: 0;
  bottom: 0;
}

.socials svg {
  height: 2rem;
  width: 2rem;
}

.socials a{
  color: #6b6888;
}

.socials a:hover{
  color: black;
}

.socials::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #6b6888;
}

.agf {
  &:before{
    content: "AGF"
  }
}

.gen {
  &:before{
    content: "Genesys";
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textbox{
  margin-top: 5%;
}

.techstack{
  display: flex;
  flex-direction: row;
  align-items: center;
  color:white;
  gap: 0.1rem;
}

.techstack svg {
  height: 20px;
  width: 20px;
}


.techstack::after {
  content: "";
  width: 100%;
  height: 1px;
  background: white;
}


.tb {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail{
  max-width:80%;
  max-height: 80%;
  border-radius: 0.4rem;
}

.project:hover {
  background-color: white;
  color: black;
  border: solid 1px black;
}

.project:hover .techstack::after {
  background: black;
}

.project:hover h2{
  color: black;
}

.project:hover a{
  color: black;
}

.project:hover h1{
  color: black;
}

@media (max-width: 1024px) {
  .projects h1{
    margin-bottom: 3%;
  }

  .about_content{
    width: 60%;
  }

  .projects-content {
    width: 60%;
  }

  .footer-row {
    width: 60%;
    margin-bottom: 10%;
  }
}

@media (max-width: 900px) {
  .about_content{
    width: 70%;
  }
  .projects-content {
    width: 70%;
  }

  .footer-row {
    width: 70%;
    margin-bottom: 15%;
  }
}

@media (max-width: 800px) {
  .about_content{
    width: 75%;
  }

  .projects-content {
    width: 75%;
  }

  .footer-row {
    width: 75%;
  }
}

@media (max-width: 700px) {
  .about_content{
    width: 80%;
  }

  .projects-content {
    width: 80%;
  }

  .footer-row {
    width: 80%;
    margin-bottom: 20%;
  }
}

@media (max-width: 650px) {
  .about_content{
    width: 95%;
  }

  .projects-content {
    width: 95%;
  }

  .footer-row {
    width: 95%;
    margin-bottom: 20%;
  }
}




@media (max-width: 550px) {
  .backgrounding{
    background-position: center;
  }
  .backgrounding h2{
    font-size: 27px;
  }

  .typetext {
    font-size: 20px;
  }

  .backgrounding h1{
    font-size: 47px;
  }

  .about_content{
    width: 80%;
  }

  .drawer {
    width: 100%;
    box-sizing: border-box;
  }

  .titlebox h1 {
    margin-bottom: 2%;
  }

  .socials svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  .column img {
    margin-right:auto;
    width: 80%;
  }
  .about h1 {
    font-size:  37px;
  }
  .about h2 {
    font-size: 23px;
  }

  .row {
    display:inline;
  }

  .subtitles h1 {
    font-size: 17px;
  }

  .subtitles h1:after {
    font-size: 19px;
  }


  .projects h1 {
    font-size:  37px;
  }
  .projects h2 {
    font-size: 23px;
  }

  .project-text h1 {
    font-size: 23px;
  }

  .project-text h2 {
    font-size: 13px;
  }

  .socials {
    margin-bottom: 5%;
  }


  .projects-content {
    width: 80%;
  }

  .footer-row {
    width: 80%;
    margin-bottom: 30%;
  }

}